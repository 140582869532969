@font-face {
  font-family: 'Lato';
  src: url('./Lato-Bold.eot');
  src:
    url('./Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('./Lato-Bold.woff') format('woff'),
    url('./Lato-Bold.ttf') format('truetype'),
    url('./Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: bolder;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-Regular.eot');
  src:
    url('./Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('./Lato-Regular.woff') format('woff'),
    url('./Lato-Regular.ttf') format('truetype'),
    url('./Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}

@font-face {
  font-family: 'Lato';
  src: url('./Lato-SemiBold.eot');
  src:
    url('./Lato-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./Lato-SemiBold.woff') format('woff'),
    url('./Lato-SemiBold.ttf') format('truetype'),
    url('./Lato-SemiBold.svg#Lato-SemiBold') format('svg');
  font-weight: bold;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}

@font-face {
  font-family: 'Linotte-SemiBold';
  src: url('./Linotte-SemiBold.eot');
  src:
    url('./Linotte-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./Linotte-SemiBold.woff') format('woff'),
    url('./Linotte-SemiBold.ttf') format('truetype'),
    url('./Linotte-SemiBold.svg#Linotte-SemiBold') format('svg');
  font-weight: bold;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}
@font-face {
  font-family: 'Kanit';
  src:
    url('./Kanit-Light.woff') format('woff'),
    url('./Kanit-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}

@font-face {
  font-family: 'Kanit';
  src:
    url('./Kanit-Medium.woff') format('woff'),
    url('./Kanit-Medium.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}
@font-face {
  font-family: 'Inconsolata';
  src:
    url('./Inconsolata-Regular.woff') format('woff'),
    url('./Inconsolata-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}

@font-face {
  font-family: 'Inconsolata';
  src:
    url('./Inconsolata-SemiBold.woff') format('woff'),
    url('./Inconsolata-SemiBold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  /* more-info: https://developers.google.com/web/updates/2016/02/font-display */
  font-display: fallback;
}
